import React, { useState } from 'react';

import lodashDebounce from 'lodash/debounce';
import styles from './NavBar.module.scss';
import DropdownMenu from './DropdownMenu/DropdownMenu';
import HamburgerMenu from './DropdownMenu/HamburgerMenu/HamburgerMenu';
import classnames from 'classnames';
import Button from 'aphrodite-shared/ui/Button/Button';
import IwocaPayIcon from '../../../../SVGComponents/iwocaPayIcon';
import TelIconSVG from '../../../../SVGComponents/TelIconSVG';
import { scrollToRef } from 'aphrodite-shared/util/helpers';

const useIsScroll = () => {
    const [navBar, setNavBar] = useState(true);

    if (typeof window !== 'undefined') {
        let prevScrollpos = window.pageYOffset;
        window.onscroll = lodashDebounce(() => {
            let currentScrollPos = window.pageYOffset;
            if (prevScrollpos > currentScrollPos || currentScrollPos < 50) {
                setNavBar(true);
            } else {
                setNavBar(false);
            }
            prevScrollpos = currentScrollPos;
        }, 1);
    }

    return navBar;
};

const NavBar = ({
    className,
    buttonText,
    surveyLink,
    phoneNumber,
    phoneLink,
    trustpilotRating,
    howItWorksRef,
    emailRef,
    autoScrollLabel
}) => {
    const [isOpen, setOpen] = useState(false);

    const onClick = () => setOpen(!isOpen);

    const isScroll = useIsScroll();

    const navBarActivity = isScroll ? '' : styles.InactiveNavBar;

    const howItWorksScroll = () => scrollToRef(howItWorksRef);
    const emailScroll = () => scrollToRef(emailRef);

    return (
        <div style={{ position: 'relative', overflowX: 'hidden' }}>
            <div className={`${styles.NavBarContainer} ${navBarActivity} `}>
                <nav className={classnames(styles.NavBar, className)}>
                    <div className={styles.paneContainer}>
                        <section className={styles.LeftPane}>
                            <IwocaPayIcon className={styles.logo} />
                            <a
                                className={styles.link}
                                onClick={howItWorksScroll}>
                                {autoScrollLabel}
                            </a>
                        </section>
                        <section className={styles.Actions}>
                            <span className={styles.ActionsWrapper}>
                                <Button
                                    colour="primary"
                                    className={styles.ApplyButton}
                                    onClick={emailScroll}
                                    dataGaId="navbar__iwocapaycta">
                                    {buttonText}
                                </Button>
                                <HamburgerMenu
                                    isOpen={isOpen}
                                    onClick={onClick}
                                />
                                <section className={styles.Telephone}>
                                    <TelIconSVG />
                                    <a
                                        href={phoneLink}
                                        data-ga-id="navbar__phone">
                                        {phoneNumber}
                                    </a>
                                </section>
                            </span>
                        </section>
                    </div>
                </nav>
                <DropdownMenu
                    isOpen={isOpen}
                    buttonText={buttonText}
                    surveyLink={surveyLink}
                    phoneNumber={phoneNumber}
                    phoneLink={phoneLink}
                    trustpilotRating={trustpilotRating}
                />
            </div>
        </div>
    );
};

export default NavBar;
