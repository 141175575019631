import React from 'react';
import styles from './FooterLinks.module.scss';
import IwLink from '../../../../components/IwLink/IwLink';

const FooterLinks = () => {
    const footerData = [
        { title: 'FAQs', url: '/faq/' },
        { title: 'Privacy policy', url: '/privacy-policy/' },
        { title: 'Terms of use', url: '/terms-of-use/' },
        { title: 'Use of cookies', url: '/use-of-cookies/' },
        { title: 'Complaint policy', url: '/complaints/' }
    ];

    return (
        <section className={styles.background}>
            <div className={styles.FooterLinks}>
                <div className={styles.footerLinksContainer}>
                    {footerData.map((link) => (
                        <IwLink className={styles.footerLink} to={link.url}>
                            {link.title}
                        </IwLink>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default FooterLinks;
