import React from 'react';
import styles from './HamburgerMenu.module.scss';
import HamburgerSVG from '../../../../../../SVGComponents/HamburgerSVG';
import CloseSVG from '../../../../../../SVGComponents/CloseSVG';

const HamburgerMenu = ({ isOpen, onClick }) => {
    const click = () => onClick();

    if (!isOpen) {
        return (
            <div
                onClick={click}
                className={styles.MenuIcon}
                data-testid="HamburgerMenu">
                <HamburgerSVG />
            </div>
        );
    } else {
        return (
            <div
                onClick={click}
                className={styles.MenuIcon}
                data-testid="HamburgerMenu">
                <CloseSVG />
            </div>
        );
    }
};

export default HamburgerMenu;
