import React from 'react';

import styles from './TrustPilot.module.scss';
import TrustPilotLogo from '../../../../components/Press/PressLogos/svg/TrustPilotLogo';

const TrustPilot = ({ rating, fill, className }) => {
    return (
        <section className={className}>
            <div className={styles.TrustPilot}>
                <a
                    href="https://uk.trustpilot.com/review/iwoca.co.uk"
                    target="_blank"
                    rel="noopener noreferrer">
                    <TrustPilotLogo fill={fill} />
                    <p>
                        {rating} Rating • <span>Excellent</span>
                    </p>
                </a>
            </div>
        </section>
    );
};

export default TrustPilot;
