import React from 'react';
import styles from './MoreAboutIwocaPay.module.scss';
import ReactMarkdown from 'react-markdown';
import GatsbyImg from 'gatsby-image';

const MoreAboutIwocaPay = ({ heading, copy, image }) => {
    return (
        <div className={styles.MoreAboutIwocaPay}>
            <div className={styles.LeftPane}>
                <div className={styles.ImageContainer}>
                    <GatsbyImg fluid={image.fluid} />
                </div>
            </div>
            <div className={styles.RightPane}>
                <div className={styles.ContentContainer}>
                    <h2>{heading}</h2>
                    <div className={styles.Divider}></div>
                    <ReactMarkdown source={copy} />
                </div>
            </div>
        </div>
    );
};

export default MoreAboutIwocaPay;
