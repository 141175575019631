import React from 'react';
import ReactMarkdown from 'react-markdown';

import styles from './HowItWorks.module.scss';

const HowItWorks = ({
    title,
    firstOptionTitle,
    firstOptionContent,
    secondOptionTitle,
    secondOptionContent,
    thirdOptionTitle,
    thirdOptionContent,
    reference
}) => {
    return (
        <section>
            <div ref={reference} className={styles.HowItWorks}>
                <h2>{title}</h2>
                <hr />
                <div className={styles.infoContainer}>
                    <div className={styles.info}>
                        <div className={styles.circle}>
                            <h1>1</h1>
                        </div>
                        <h3>{firstOptionTitle}</h3>
                        <ReactMarkdown source={firstOptionContent} />
                    </div>
                    <div className={styles.info}>
                        <div className={styles.circle}>
                            <h1>2</h1>
                        </div>
                        <h3>{secondOptionTitle}</h3>
                        <ReactMarkdown source={secondOptionContent} />
                    </div>
                    <div className={styles.info}>
                        <div className={styles.circle}>
                            <h1>3</h1>
                        </div>
                        <h3>{thirdOptionTitle}</h3>
                        <ReactMarkdown source={thirdOptionContent} />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HowItWorks;
