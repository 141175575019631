import React, { useState } from 'react';
import { BasicSquare, WedgedArch } from '../../../../SVGComponents/shapes';
import styles from './WhyIwocaPay.module.scss';

const WhyIwocaPay = ({ title, reasons }) => {
    let [activeSlide, setActiveSlide] = useState(0);
    return (
        <section className={styles.background}>
            <WedgedArch className={styles.WedgedArch} />
            <BasicSquare className={styles.BasicSnowSquare} color="snow" />
            <BasicSquare
                className={styles.BasicCoralSquare}
                rotate="right"
                size="sm"
            />

            <div className={styles.Values}>
                <h2>{title}</h2>
                <hr />
                <div className={styles.actionsContainer}>
                    {reasons.map((value, i) => (
                        <button
                            className={
                                i === activeSlide ? `${styles.active}` : ''
                            }
                            onClick={() => setActiveSlide(i)}
                            key={i}>
                            {value.name}
                        </button>
                    ))}
                </div>
                <div className={styles.slideContainer}>
                    {reasons.map((value, i) => (
                        <div
                            className={
                                i === activeSlide
                                    ? `${styles.slide} ${styles.active}`
                                    : `${styles.slide}`
                            }
                            key={i}
                            data-testid="reasons-slide">
                            <h3>{value.name}</h3>
                            <p>{value.description}</p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default WhyIwocaPay;
