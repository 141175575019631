import React from 'react';
import ReactMarkdown from 'react-markdown';
import styles from './HeroIwocaPay.module.scss';
import TrustPilot from '../TrustPilot/TrustPilot';

const HeroIwocaPay = ({ title, content, rating, reference }) => {
    return (
        <section className={styles.background}>
            <div className={styles.HeroIwocaPay}>
                <h1 ref={reference}>{title}</h1>
                <ReactMarkdown source={content} />
                <TrustPilot rating={rating} fill="white" />
            </div>
        </section>
    );
};

export default HeroIwocaPay;
