import React, { useEffect } from 'react';
import styles from './DropdownMenu.module.scss';
import MenuShapes from './shapes/MenuShapes';
import Button from 'aphrodite-shared/ui/Button/Button';
import TelIconSVG from '../../../../../SVGComponents/TelIconSVG';
import TrustPilot from '../../TrustPilot/TrustPilot';

const useDropDown = (isOpen) => {
    useEffect(() => {
        if (typeof document !== 'undefined') {
            if (isOpen) {
                // // make the menu the focus when open
                document.body.style.overflow = 'hidden';
                document.documentElement.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = 'auto';
                document.documentElement.style.overflow = 'auto';
            }
        }

        return function cleanup() {
            document.body.style.overflow = 'auto';
            document.documentElement.style.overflow = 'auto';
        };
    });
};

const DropdownMenu = ({
    isOpen,
    buttonText,
    surveyLink,
    phoneNumber,
    phoneLink,
    trustpilotRating
}) => {
    const activeStyle = isOpen ? styles.Active : '';

    useDropDown(isOpen);
    return (
        <section
            className={`${styles.Menu} ${activeStyle}`}
            data-testid="DropdownMenu">
            <MenuShapes isOpen={isOpen}>
                <ul className={styles.ExtraOptions}>
                    <li>
                        <Button
                            href={surveyLink}
                            colour="primary"
                            className={styles.ApplyButton}
                            dataGaId="iwocapay__mobileMenu__check_eligibility">
                            {buttonText}
                        </Button>
                    </li>
                    <li>
                        <TrustPilot rating={trustpilotRating} />
                    </li>
                    <li>
                        <section className={styles.Telephone}>
                            <TelIconSVG />
                            <a href={phoneLink} data-ga-id="mobileMenu__phone">
                                {phoneNumber}
                            </a>
                        </section>
                    </li>
                </ul>
            </MenuShapes>
        </section>
    );
};

export default DropdownMenu;
