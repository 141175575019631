import React from 'react';

import styles from './FooterContact.module.scss';
import TelIconSVG from '../../../../SVGComponents/TelIconSVG';
import EmailIcon from '../../../../components/Footer/svg/EmailIcon';
import Button from 'aphrodite-shared/ui/Button/Button';
import IwocaPayIcon from '../../../../SVGComponents/iwocaPayIcon';
import { scrollToRef } from 'aphrodite-shared/util/helpers';

const FooterContact = ({
    emailAddress,
    phoneNumber,
    phoneLink,
    button,
    emailRef
}) => {
    const emailLink = `mailto:${emailAddress}`;

    const emailScroll = () => scrollToRef(emailRef);

    return (
        <section className={styles.background}>
            <div className={styles.FooterContact}>
                <div className={styles.logo}>
                    <IwocaPayIcon />
                </div>
                <div className={styles.email}>
                    <EmailIcon />
                    <a href={emailLink} data-ga-id="iwocapay__footer__email">
                        {emailAddress}
                    </a>
                </div>
                <div className={styles.tel}>
                    <TelIconSVG />
                    <a href={phoneLink} data-ga-id="iwocapay__footer__phone">
                        {phoneNumber}
                    </a>
                </div>
                <div className={styles.apply}>
                    <Button
                        colour="primary"
                        dataGaId="iwocapay__footer__check_eligibility"
                        onClick={emailScroll}>
                        {button}
                    </Button>
                </div>
            </div>
        </section>
    );
};

export default FooterContact;
