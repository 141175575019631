import React from 'react';
import classnames from 'classnames';
import styles from './shapes.module.scss';
import BasicCircle from '../../../../../../SVGComponents/shapes/Circles/BasicCircle';

const MenuShapes = ({ children, isOpen }) => {
    const activeStyle = isOpen ? styles.Active : '';

    return (
        <>
            <BasicCircle
                color="denim"
                className={classnames(styles.BasicCircle, activeStyle)}
            />

            {children}
        </>
    );
};

export default MenuShapes;
