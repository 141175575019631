import React, { useRef } from 'react';
import { graphql } from 'gatsby';

import MetaData from '../../../components/Layout/MetaData.jsx';
import styles from '../iwocaPage.module.scss';
import WhyIwocaPay from './WhyIwocaPay/WhyIwocaPay';
import HeroIwocaPay from './HeroIwocaPay/HeroIwocaPay';
import FooterContact from './FooterContact/FooterContact';
import MoreAboutIwocaPay from './MoreAboutIwocaPay/MoreAboutIwocaPay';
import FooterLinks from './FooterLinks/FooterLinks';
import HowItWorks from './HowItWorks/HowItWorks';
import NavBar from './NavBar/NavBar';

const IwocaPaySellers = ({ data }) => {
    const { contentfulPageIwocaPaySellersPage: pageData } = data;
    const {
        title,
        metaDescription,
        allowSearchEngineScraping = true,
        url,
        headerTitle,
        headerIntro,
        headerButton,
        headerTrustpilot,
        whyIwocaPayTitle,
        whyIwocaPayNo1Title,
        whyIwocaPayNo1Description,
        whyIwocaPayNo2Title,
        whyIwocaPayNo2Description,
        whyIwocaPayNo3Title,
        whyIwocaPayNo3Description,
        iwocaPayEmail,
        iwocaPayPhoneNumber,
        navBarButton,
        moreAboutSectionTitle,
        moreAboutSectionDescription,
        moreAboutSectionImage,
        howItWorksTitle,
        howItWorksNo1Title,
        howItWorksNo1Description,
        howItWorksNo2Title,
        howItWorksNo2Description,
        howItWorksNo3Title,
        howItWorksNo3Description
    } = pageData;

    const reasons = [
        {
            name: whyIwocaPayNo1Title,
            description: whyIwocaPayNo1Description.whyIwocaPayNo1Description
        },
        {
            name: whyIwocaPayNo2Title,
            description: whyIwocaPayNo2Description.whyIwocaPayNo2Description
        },
        {
            name: whyIwocaPayNo3Title,
            description: whyIwocaPayNo3Description.whyIwocaPayNo3Description
        }
    ];

    let internationalPhoneNumber = iwocaPayPhoneNumber;
    if (internationalPhoneNumber && internationalPhoneNumber.startsWith('0')) {
        internationalPhoneNumber = internationalPhoneNumber.replace('0', '+44');
    }
    const phoneLink =
        internationalPhoneNumber &&
        `tel:${internationalPhoneNumber.replace(/ /g, '')}`;

    const howItWorksRef = useRef(null);
    const emailRef = useRef(null);

    return (
        <div className={styles.Page}>
            <MetaData
                metaData={{
                    titleTag: title,
                    metaDescription,
                    allowSearchEngineScraping,
                    url
                }}
            />
            <NavBar
                buttonText={navBarButton}
                phoneNumber={iwocaPayPhoneNumber}
                phoneLink={phoneLink}
                trustpilotRating={headerTrustpilot.trustpilotRating}
                howItWorksRef={howItWorksRef}
                emailRef={emailRef}
                autoScrollLabel={howItWorksTitle}
            />
            <HeroIwocaPay
                title={headerTitle}
                content={headerIntro.headerIntro}
                rating={headerTrustpilot.trustpilotRating}
                reference={emailRef}
            />
            <HowItWorks
                title={howItWorksTitle}
                firstOptionTitle={howItWorksNo1Title}
                firstOptionContent={
                    howItWorksNo1Description.howItWorksNo1Description
                }
                secondOptionTitle={howItWorksNo2Title}
                secondOptionContent={
                    howItWorksNo2Description.howItWorksNo2Description
                }
                thirdOptionTitle={howItWorksNo3Title}
                thirdOptionContent={
                    howItWorksNo3Description.howItWorksNo3Description
                }
                reference={howItWorksRef}
            />
            <WhyIwocaPay title={whyIwocaPayTitle} reasons={reasons} />
            <MoreAboutIwocaPay
                heading={moreAboutSectionTitle}
                copy={moreAboutSectionDescription.moreAboutSectionDescription}
                image={moreAboutSectionImage}
            />
            <FooterContact
                emailAddress={iwocaPayEmail}
                phoneNumber={iwocaPayPhoneNumber}
                phoneLink={phoneLink}
                button={headerButton}
                emailRef={emailRef}
            />
            <FooterLinks />
            <script
                type="text/javascript"
                id="hs-script-loader"
                async
                defer
                src="//js.hs-scripts.com/6334445.js"></script>
        </div>
    );
};

export default IwocaPaySellers;

export const query = graphql`
    query IwocaPaySellersPageQuery($id: String!) {
        contentfulPageIwocaPaySellersPage(id: { eq: $id }) {
            title
            url
            metaDescription
            allowSearchEngineScraping
            headerTitle
            headerIntro {
                headerIntro
            }
            headerButton
            hubspotListId
            headerTrustpilot {
                ...trustpilotRating
            }
            whyIwocaPayTitle
            whyIwocaPayNo1Title
            whyIwocaPayNo1Description {
                whyIwocaPayNo1Description
            }
            whyIwocaPayNo2Title
            whyIwocaPayNo2Description {
                whyIwocaPayNo2Description
            }
            whyIwocaPayNo3Title
            whyIwocaPayNo3Description {
                whyIwocaPayNo3Description
            }
            iwocaPayEmail
            iwocaPayPhoneNumber
            navBarButton
            moreAboutSectionTitle
            moreAboutSectionDescription {
                moreAboutSectionDescription
            }
            moreAboutSectionImage {
                fluid(maxWidth: 688, quality: 90) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                }
            }
            howItWorksTitle
            howItWorksNo1Title
            howItWorksNo1Description {
                howItWorksNo1Description
            }
            howItWorksNo2Title
            howItWorksNo2Description {
                howItWorksNo2Description
            }
            howItWorksNo3Title
            howItWorksNo3Description {
                howItWorksNo3Description
            }
        }
    }
`;
